import { navigate } from 'gatsby';
import React from 'react';

import UI from '#front-credpago';

import Layout from '../components/layout/index';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout internal={true} displayImobsParceiras={false}>
    <SEO title="404: Not found" />
    <div style={{ textAlign: 'center', padding: '4em 0' }}>
      <UI.Title splitLabel={['Página não', 'encontrada']} />
      <p>A página que você procurou não existe.</p>
      <br />
      <UI.Button onClick={() => navigate('/')} type="secondary">
        Voltar para a página inicial
      </UI.Button>
    </div>
  </Layout>
);

export default NotFoundPage;
